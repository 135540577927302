<template>
  <div class="lproject_image">
    <div class="nodata"
         v-if="fileList.length==0">没有数据</div>
    <viewer :images="fileList"
            class="viewer"
            v-else>
      <div v-for="(item, index) in fileList"
           :key="index"
           class="img-container">
        <div class="img-item">
          <img :src="item.url">
          <span>{{item.name}}</span>
        </div>
      </div>
    </viewer>
  </div>
</template>

<script>
export default {
  name: 'lprojectImage',
  props: {
    uid: {
      type: String,
      default: () => { return '' }
    }
  },
  data () {
    return {
      folder: '/media',
      fileList: []
    }
  },
  watch: {
    uid: {
      handler (val) {
        this.folder = `${this.folder}/${val}.GCTK`
        this.getFileData()
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    // 文件列表result
    this.ws.addCallback('folderContentAck', (res) => {
      this.loading = false;
      if (this.common.cGetResult(res)) {
        let arr = new Array();
        let loc = window.location;
        let url = `${loc.protocol}//${loc.host}`;
        //是在node.js环境下运行,连接IotPlatform.exe，这里主要方便测试显示出图片
        if (loc.port == "8080") {
          url = `${loc.protocol}//${document.domain}`;
        }
        for (let item of res.items) {
          let nameFormat = item.name.indexOf(".") != -1 ? item.name.substring(0, item.name.indexOf('.')) : item.name;
          arr.push({
            type: item.type,
            name: nameFormat,
            size: item.type == 'folder' ? '-' : this.common.cByteConversion(item.size),
            url: item.type == 'folder' ? '' : url + this.folder + "/" + item.name,
            checked: false
          })
        }
        this.fileList = arr;
      }
    })
  },
  methods: {
    getFileData () {
      let json = {
        "cmd": "folderContent",
        "folder": this.folder
      }
      this.ws.send(json);
    }
  }
}
</script>

<style lang="scss">
.lproject_image {
  .viewer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 20px;
    grid-column-gap: 20px;

    .img-container {
      position: relative;
      .img-item {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        img {
          width: 100%;
          height: auto;
        }

        span {
          width: 100%;
          height: 35px;
          line-height: 35px;
          position: absolute;
          bottom: 0;
          background-color: rgba($color: #000000, $alpha: 0.3);
          font-size: 16px;
          color: #ffffff;
          text-align: center;
        }
      }
    }
  }
  .nodata {
    margin-top: 100px;
    text-align: center;
  }
}
</style>